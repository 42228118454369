import styles from './ToastNote.module.scss';

interface I_Props {
	text?: string;
	children?: React.ReactNode;
	icon: React.ReactNode;
	variant: 'bgGhostWhite' | 'bgSecondary100Greyscale300' | 'bgCosmicLatte' | 'bgSeaShell';
	color: 'ltGreyscale700';
}
const ToastNote = ({ text, icon, variant, color, children }: I_Props) => {
	return (
		<div className={`${styles.toastNote} ${styles[variant]}`}>
			<div className={styles.icon}>{icon}</div>
			{text && <div className={`${color} ltbody_md__regular w-full`}>{text}</div>}
			{children && <div className={`${color} ltbody_md__regular w-full`}>{children}</div>}
		</div>
	);
};

export default ToastNote;
