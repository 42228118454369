import styles from './Badge.module.scss';

interface I_BadgeProps {
	text: string;
	variant?: 'successDark' | 'errorBase' | 'orange' | 'brightGrey';
	color?: 'primaryBase';
	type?: 'appointment' | 'status' | 'lab-cart';
}

const Badge = ({ text, variant, color, type }: I_BadgeProps) => {
	return (
		<div className={`${styles.badge}`}>
			<span
				className={`${styles[variant]} ${color ? styles[color] : 'white'} ${type === 'lab-cart' ? 'ltbody_sm__regular' : 'ltbody_md__regular'} t_nowrap capitalize`}>
				{text}
			</span>

			<svg xmlns='http://www.w3.org/2000/svg' width='15' height='22' viewBox='0 0 15 22' fill='none'>
				<path
					d='M0 0H13.1284C14.8073 0 15.6338 2.18707 14.4273 3.4372L8.51586 9.5628C7.75733 10.3488 7.75733 11.6512 8.51586 12.4372L14.4273 18.5628C15.6338 19.8129 14.8073 22 13.1284 22H0V0Z'
					// fill='#F2F2F2'
					className={styles[variant]}
				/>
			</svg>
		</div>
	);
};

export default Badge;
