import { SVGAttributes } from 'react';
import styles from './DiscountChip.module.scss';

interface I_Props {
	text: string;
	chipItem?: 'testCard' | 'cart';
}

const DiscountChip = ({ text, chipItem = 'testCard' }: I_Props) => {
	return (
		<div className={styles.discountChid}>
			{chipItem === 'testCard' && (
				<div className={styles.discountChid_test}>
					<LT_Test_Chip_left />
					<p className='ltbody_sm__semibold white t_nowrap'>{text}</p>
					<LT_Test_Chip_right />
				</div>
			)}

			{chipItem === 'cart' && (
				<div className={styles.discountChid_cart}>
					<LT_Test_Cart_Chip_top />
					<div className='d-flex flex-column items-center'>
						<p className='white t_nowrap'>{text}%</p>
						<p className='white t_nowrap'>OFF</p>
					</div>
					<div
						style={{
							marginTop: '-1px'
						}}>
						<LT_Test_Cart_Chip_bottom />
					</div>
				</div>
			)}
		</div>
	);
};

export default DiscountChip;

interface IconProps extends SVGAttributes<SVGElement> {
	size?: number | string;
	color?: string;
	className?: string;
}

export function LT_Test_Chip_left(props: IconProps): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={8} height={20} viewBox='0 0 8 20' fill='none' {...props}>
			<path d='M5.5 0L0 10l5.5 10H8V0H5.5z' fill='#FD6A6A' />
		</svg>
	);
}

export function LT_Test_Chip_right(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={14} height={20} viewBox='0 0 14 20' fill='none' {...props}>
			<path d='M1.577 20L7 10 1.577 0H0v20h1.577z' fill='#FD6A6A' />
			<path fillRule='evenodd' clipRule='evenodd' d='M8 10L2.5 20h11L8 10zm5.5-10L8 10 2.5 0h11z' fill='#FE9A9A' />
		</svg>
	);
}

export function LT_Test_Cart_Chip_top(props) {
	return (
		<svg width='25' height='4' viewBox='0 0 25 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect width='25' height='4' fill='#2168DE' />
		</svg>
	);
}

export function LT_Test_Cart_Chip_bottom(props) {
	return (
		<svg width='25' height='6' viewBox='0 0 25 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0 3.63321V0.921875C0 0.944664 0.0184739 0.963138 0.0412629 0.963138H24C24.5523 0.963138 25 1.41085 25 1.96314V3.63321C25 4.50569 23.9608 4.95974 23.3206 4.367L22.437 3.54894C22.0658 3.20521 21.4963 3.19292 21.1106 3.5203L18.9568 5.34821C18.5711 5.67559 18.0016 5.66329 17.6304 5.31957L15.6927 3.52556C15.3324 3.19196 14.7832 3.16932 14.3966 3.47215L12.0116 5.34063C11.5949 5.66706 10.9961 5.61226 10.6456 5.21562L9.2895 3.68114C8.92897 3.27313 8.30825 3.22857 7.89312 3.5809L5.84388 5.3201C5.44424 5.65928 4.85051 5.63231 4.48327 5.25829L2.89384 3.63954C2.5149 3.25361 1.89779 3.23892 1.50092 3.60637L0.67938 4.367C0.03918 4.95974 0 4.50569 0 3.63321Z'
				fill='#0062FF'
			/>
		</svg>
	);
}
