import Radio from '@labtest/presentation/atoms/Radio';
import Image from 'next/image';

import { getFormattedLabVendorItemAndPrice } from '@labServices/utils/cmnHelper';
import { Dispatch, SetStateAction } from 'react';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';

import type { I_Vendor, I_VendorLabItem } from '@labServices/apis/types/lab-tests/type.getPopularLabPackages';
import type { IG_LT_LabVendor } from '@labServices/apis/types/lab-vendor/type.getLabVendor';

import styles from './SelectLabVendorCard.module.scss';

interface I_Props {
	data: I_VendorLabItem | IG_LT_LabVendor;
	selectedVendorId?: string;
	setSelectedVendorId?: Dispatch<SetStateAction<string>>;
	setSelectedVendor?: Dispatch<SetStateAction<I_VendorLabItem | IG_LT_LabVendor | I_Vendor>>;
	cartVendorId?: string;
	isEmptyCartItem?: boolean;
	type?: 'cart';
	vendorStatus?: 'active' | 'inactive';
}

const SelectLabVendorCard = ({
	data,
	selectedVendorId,
	setSelectedVendor,
	setSelectedVendorId,
	cartVendorId,
	isEmptyCartItem,
	type,
	vendorStatus
}: I_Props) => {
	const formattedVendor = getFormattedLabVendorItemAndPrice(data as I_VendorLabItem, null, cartVendorId);
	const matchSelectedVendor =
		selectedVendorId === formattedVendor?.labItem?.vendor?.id || selectedVendorId === data?.id;

	const handleSelectLabVendor = () => {
		if (type === 'cart') return setSelectedVendorId?.(data?.id);

		if (formattedVendor?.labItem?.status === 'inactive') return;

		if (isEmptyCartItem) {
			setSelectedVendorId?.(formattedVendor?.labItem?.vendor?.id);
			setSelectedVendor?.(formattedVendor?.labItem?.vendor);
			return;
		}

		setSelectedVendorId?.(formattedVendor?.labItem?.vendor?.id || data?.id);
		setSelectedVendor?.(formattedVendor?.labItem?.vendor || data);

		// handleCloseSelectPatientModal && handleCloseSelectPatientModal();
		// setTimeout(() => {
		// 	handleOpenSwitchLabConfModal?.();
		// }, 300);
	};

	return (
		<div
			className={`${styles.SelectLabVendorCard} ${matchSelectedVendor ? styles.__active : vendorStatus === 'active' ? styles.__hover : ''}`}
			onClick={() => vendorStatus === 'active' && handleSelectLabVendor()}>
			<div className={styles.selectAction}>
				{vendorStatus === 'inactive' ? (
					<MdOutlineRadioButtonUnchecked size={27} color='var(--ltGreyscale500)' />
				) : (
					<Radio
						name={'vendor'}
						checked={selectedVendorId}
						value={formattedVendor?.labItem?.vendor?.id || data?.id}
						// label={'All Packages'}
						btnSize='w-22 h-22'
						textSize='ltbody_md__regular'
					/>
				)}
			</div>

			<div className={styles.labVendorContainer}>
				<div className={styles.icon}>
					<Image
						src={formattedVendor?.labItem?.vendor?.image?.web || data?.image?.web}
						alt={formattedVendor?.labItem?.vendor?.name?.en || data?.name?.en}
						width={60}
						height={60}
						unoptimized
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
							objectPosition: 'center'
						}}
					/>
				</div>
				<div className={styles.details}>
					<div className={styles.labName}>
						<p
							className={`${vendorStatus === 'inactive' ? 'ltGreyscale500' : 'ltGreyscale900'} ltbody_lg__semibold`}>
							{formattedVendor?.labItem?.vendor?.name?.en || data?.name?.en}
						</p>
						{vendorStatus === 'inactive' && <p className='ltGreyscale500 ltbody_md__medium mt-2'>Not Offered</p>}
					</div>
					{type !== 'cart' && (
						<>
							{vendorStatus === 'inactive' ? (
								<div className='ltbody_lg__semibold ltGreyscale500'>৳0</div>
							) : (
								<div className={styles.price}>
									{formattedVendor.testPrice?.hasDiscount ? (
										<>
											<p className='ltbody_md__regular ltGreyscale500 text_line_through'>
												৳{formattedVendor.testPrice?.regularPrice}
											</p>
											<p className='ltbody_lg__semibold ltGreyscale900'>
												৳{formattedVendor.testPrice?.discountPrice}
											</p>
										</>
									) : (
										<p className='ltbody_lg__semibold ltGreyscale900'>
											৳{formattedVendor.testPrice?.regularPrice}
										</p>
									)}
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default SelectLabVendorCard;
