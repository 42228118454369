import Button from '@labtest/presentation/atoms/Button';
import Image from 'next/image';
import { Dispatch, SetStateAction } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { MdClose } from 'react-icons/md';
import styles from './SwitchLabConfirmationCard.module.scss';

interface I_Props {
	handleCloseSwitchLabConf: () => void;
	handleOpenSelectPatientModal?: () => void;
	cartVendor: any;
	selectedVendor: any;
	setSelectedVendorId: Dispatch<SetStateAction<string>>;
	handleAddTestToCart: () => void;
	setPatientCount: Dispatch<SetStateAction<number>>;
	currentItemPatientCount: number;
}

const SwitchLabConfirmationCard = ({
	cartVendor,
	selectedVendor,
	setSelectedVendorId,
	handleOpenSelectPatientModal,
	handleCloseSwitchLabConf,
	handleAddTestToCart,
	setPatientCount,
	currentItemPatientCount
}: I_Props) => {
	const handleCancelSwitchLab = () => {
		setSelectedVendorId(cartVendor?.id);
		setPatientCount(currentItemPatientCount || 1);
		handleCloseSwitchLabConf();
		// setTimeout(() => {
		// 	handleOpenSelectPatientModal();
		// }, 300);
	};

	const handleConfirmSwitchLab = () => {
		handleCloseSwitchLabConf();
		handleAddTestToCart();
	};
	return (
		<div className={styles.switchLabConfirmationCard}>
			<MdClose
				size={24}
				color='var(--ltGreyscale400)'
				// onClick={handleCloseSwitchLabConf}
				onClick={handleCancelSwitchLab}
				style={{
					position: 'absolute',
					top: '20px',
					right: '20px',
					cursor: 'pointer'
				}}
			/>

			<div className={styles.contents}>
				<div className='flex gap-14 mb-20'>
					<div className={styles.image}>
						<Image
							src={cartVendor?.image?.web}
							alt={cartVendor?.name?.en}
							width={60}
							height={60}
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								objectPosition: 'center'
							}}
						/>
					</div>

					<FaArrowRight size={24} color='var(--ltPrimary)' />

					<div className={styles.image}>
						<Image
							src={selectedVendor?.image?.web}
							alt={selectedVendor?.image?.web}
							width={60}
							height={60}
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								objectPosition: 'center'
							}}
						/>
					</div>
				</div>
				<div className='lth4_semibold ltGreyscale900 mb-10'>Changing your Lab</div>
				<p className='ltbody_lg__regular ltGreyscale700 text-center'>
					Do you want to change your previous lab selection from{' '}
					<span className='ltbody_lg__semibold'>{cartVendor?.name?.en}</span> to{' '}
					<span className='ltbody_lg__semibold'>{selectedVendor?.name?.en}</span> ?
				</p>
			</div>
			<div className={styles.action_btns}>
				<Button
					variant='bgWhiteGreyscale300'
					color='clGreyscale700'
					size='fullWidth'
					onClick={handleCancelSwitchLab}>
					Cancel
				</Button>
				<Button variant='bgPrimary' size='fullWidth' onClick={handleConfirmSwitchLab}>
					Confirm
				</Button>
			</div>
		</div>
	);
};

export default SwitchLabConfirmationCard;
