import styles from './Radio.module.scss';

interface I_Props {
	checked: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	name: string;
	label?: string;
	btnSize?: string;
	textSize?: string;
	value: string;
	defaultValue?: string;
}

const Radio = ({ checked, onChange, name, label, btnSize, textSize, value, defaultValue, ...rest }: I_Props) => {
	return (
		<label className={styles.radio_button}>
			<div className={`flex relative`}>
				<input
					type='radio'
					onChange={onChange}
					name={name}
					checked={checked === value || checked === label}
					value={defaultValue || value}
					{...rest}
				/>
				<span className={`${styles.fake_radiobutton} ${btnSize}`} />
			</div>
			{label && <p className={`${styles.label} ${textSize}`}>{label}</p>}
		</label>
	);
};

export default Radio;
