import Button from '@/labtest/presentation/atoms/Button';

import {
	v1_delete_error_icon,
	v1_lab_science,
	v1_not_available_service_image,
	v1_not_available_test_image
} from '@labServices/utils/svg';
import { FaCircleCheck } from 'react-icons/fa6';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdClose, MdEdit } from 'react-icons/md';

import type { MouseEvent } from 'react';
import { RiDeleteBin6Fill } from 'react-icons/ri';

import IconWrapper from '../../atoms/IconWrapper';
import styles from './PopUpModal.module.scss';

interface I_Props {
	title?: string;
	errorTag?:
		| 'order_cancel'
		| 'item_unavailable'
		| 'service_unavailable'
		| 'item_max_reached'
		| 'order_in_maintenance'
		| string;
	message?: string;
	modalType:
		| 'delete'
		| 'not_available'
		| 'online_payment_success'
		| 'online_payment_failed'
		| 'update_payment_details';
	closeModal?: () => void;
	cancelDelete?: (e: MouseEvent<SVGAElement | HTMLButtonElement | HTMLDivElement>) => void;
	confirmDelete?: () => void;
}

const PopUpModal = ({ title, errorTag, message, modalType, closeModal, cancelDelete, confirmDelete }: I_Props) => {
	return (
		<div className={styles.container}>
			{closeModal && (
				<MdClose
					size={24}
					color='#98A2B3'
					style={{
						position: 'absolute',
						top: '15px',
						right: '15px',
						cursor: 'pointer'
					}}
					onClick={closeModal}
				/>
			)}

			<div className={styles.icon_container_wrap}>
				{modalType === ModalType.DELETE && (
					<div className={styles.icon_container}>
						<RiDeleteBin6Fill size={32} color='var(--ltErrorBase)' />
					</div>
				)}
				{modalType === ModalType.ONLINE_PAYMENT_SUCCESS && <FaCircleCheck size={56} color='var(--ltPrimary)' />}
				{modalType === ModalType.ONLINE_PAYMENT_FAILED && <IoMdCloseCircle size={56} color='var(--ltPrimary)' />}
				{modalType === ModalType.UPDATE_PAYMENT_DETAILS && (
					<IconWrapper variant='bgCultured'>
						<MdEdit size={32} color='var(--ltPrimary)' />
					</IconWrapper>
				)}

				{/* Error Tag */}

				{modalType === ModalType.NOT_AVAILABLE && errorTag && (
					<div className={styles.icon_container}>
						{errorTag === ErrorTag.ITEM_UNAVAILABLE && <>{v1_not_available_test_image}</>}
						{errorTag === ErrorTag.SERVICE_UNAVAILABLE && <>{v1_not_available_service_image}</>}
						{errorTag === ErrorTag.ITEM_MAX_REACHED && <>{v1_lab_science}</>}
						{errorTag === ErrorTag.ORDER_IN_MAINTENANCE && (
							<div className={`${styles.icon_container}`}>{v1_delete_error_icon}</div>
						)}
					</div>
				)}

				{/* Default */}
				{modalType === ModalType.NOT_AVAILABLE && !errorTag && (
					<div className={styles.icon_container}>{v1_delete_error_icon}</div>
				)}

				<div className={styles.content_container}>
					<p>{title || 'Sorry'}</p>
					<p>{message || 'Internal Server Error'}</p>
				</div>
			</div>

			<div className={styles.button_container}>
				{modalType === ModalType.NOT_AVAILABLE && (
					<Button variant='bgPrimary' fullWidth onClick={cancelDelete}>
						Ok
					</Button>
				)}

				{(modalType === ModalType.ONLINE_PAYMENT_SUCCESS || modalType === ModalType.ONLINE_PAYMENT_FAILED) && (
					<Button variant='bgPrimary' fullWidth onClick={closeModal}>
						Okay
					</Button>
				)}

				{(modalType === ModalType.DELETE || modalType === ModalType.UPDATE_PAYMENT_DETAILS) && (
					<>
						<Button variant={'bgWhiteGreyscale300'} color={'clGreyscale900'} fullWidth onClick={cancelDelete}>
							No
						</Button>

						<Button variant={'bgPrimary'} fullWidth onClick={confirmDelete}>
							Yes
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default PopUpModal;

const ModalType = {
	DELETE: 'delete',
	NOT_AVAILABLE: 'not_available',
	ONLINE_PAYMENT_SUCCESS: 'online_payment_success',
	ONLINE_PAYMENT_FAILED: 'online_payment_failed',
	UPDATE_PAYMENT_DETAILS: 'update_payment_details'
};

const ErrorTag = {
	ORDER_CANCEL: 'order_cancel',
	ITEM_UNAVAILABLE: 'item_unavailable',
	SERVICE_UNAVAILABLE: 'service_unavailable',
	ITEM_MAX_REACHED: 'item_max_reached',
	ORDER_IN_MAINTENANCE: 'order_in_maintenance'
};
