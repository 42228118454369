'use client';

import useAuth from '@/contexts/AuthProvider';
import Button from '@/labtest/presentation/atoms/Button';
import useLabCartContext from '@labServices/context/LabCartContext';
import useToggle from '@labServices/hooks/use-toggle';
import BookforItem from '@labtest/presentation/atoms/BookforItem';
import Dropdown from '@labtest/presentation/atoms/Dropdown';
import Line from '@labtest/presentation/atoms/Line';
import DialogModal from '@labtest/presentation/atoms/Modal/DialogModal';
import StateFullModal from '@labtest/presentation/atoms/Modal/StateFullModal';
import ToastNote from '@labtest/presentation/atoms/ToastNote';
import ModalHeader from '@labtest/presentation/molecules/ModalHeader';
import SelectLabTestCard from '@labtest/presentation/molecules/SelectLabTestCard';
import SelectLabVendorCard from '@labtest/presentation/molecules/SelectLabVendorCard';
import SelectTestPatientCard from '@labtest/presentation/molecules/SelectTestPatientCard';
import SwitchLabConfirmationCard from '@labtest/presentation/molecules/SwitchLabConfirmationCard';
import PopUpModal from '@labtest/presentation/organisms/PopUpModal';

import { cartStorageName } from '@/services/ecommerce/apis/config';
import { getMatchedAndUnmatchedVendors, isEmptyArray } from '@labServices/utils/cmnHelper';
import { getItemFromLocalStorage } from '@labServices/utils/session';
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react';
import { MdClose, MdInfo } from 'react-icons/md';

import type { I_CartItem } from '@labServices/apis/types/lab-cart/type.addTestToCart';
import type { IG_LT_MostBookedTest } from '@labServices/apis/types/lab-tests/type.getMostBookedLabTests';
import type { I_VendorLabItem } from '@labServices/apis/types/lab-tests/type.getPopularLabPackages';
import type { IG_LT_TestDetails } from '@labServices/apis/types/lab-tests/type.getTestOrPackageDetails';
import type { I_NotAvailableResState } from '@labServices/types/global-types';

import styles from './BookTestButton.module.scss';

interface I_Props {
	testId: string;
	testData: IG_LT_MostBookedTest | IG_LT_TestDetails | I_CartItem;
	placeHolder?: string;
	dropDownClassName?: string;
	selectedLabel?: string;
	variant?: 'cart_btn' | 'test_btn';
	isUnavailable?: boolean;
	setShowLoginModal: Dispatch<SetStateAction<boolean>>;
}

const BookTestButton = ({
	testId,
	testData,
	placeHolder = 'Book Test',
	setShowLoginModal,
	dropDownClassName = null,
	selectedLabel = null,
	variant,
	isUnavailable = false
}: I_Props) => {
	const { cartData, labTestCartAction, removeLabTestCartItem } = useLabCartContext();
	const { isLogged } = useAuth();
	const [openSwitchLabConf, handleOpenSwitchLabConf, handleCloseSwitchLabConf] = useToggle();
	const [openSelectPatient, handleOpenSelectPatientModal, handleCloseSelectPatientModal] = useToggle();
	const [openNotfound, handleOpenNotFound, handleCloseNotfound] = useToggle();

	const [notAvailableRes, setNotAvailableRes] = useState<Partial<I_NotAvailableResState>>({});
	const [selectedVendor, setSelectedVendor] = useState<Partial<I_VendorLabItem>>({});
	const [selectedVendorId, setSelectedVendorId] = useState<string>('');
	const [patientCount, setPatientCount] = useState<number>(1);

	const cartObj = getItemFromLocalStorage(cartStorageName);

	const patientArray: (number | string)[] = [1, 2, 3, 4, 5];
	const currentItem =
		cartObj?.items && cartData?.items ? cartData?.items?.find((item) => item?.item?.id === testId) : null;
	const isAlreadyInCart = currentItem ? true : false;
	const isSwitchVendor =
		!isEmptyArray(cartData?.items) && selectedVendorId && cartData?.vendor?.id !== selectedVendorId;

	const { matchedVendors, unmatchedVendors } = getMatchedAndUnmatchedVendors(
		testData?.vendorLabItems,
		cartData?.vendor?.id
	);

	const addToCartBtnDisabled = () => {
		if (isEmptyArray(cartData?.items) && selectedVendor?.status === 'inactive') {
			return true;
		}
		if (
			!isEmptyArray(cartData?.items) &&
			((selectedVendorId === cartData?.vendor?.id && patientCount === currentItem?.patientCount) ||
				selectedVendor?.status === 'inactive')
		) {
			return true;
		}

		return false;
	};

	useEffect(() => {
		if (currentItem) {
			patientArray.shift();
		} else {
			patientArray.unshift('Remove Test');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cartData]);

	const handleNotFoundModal = (res) => {
		setNotAvailableRes(res);
		handleOpenNotFound();
	};

	const handleRemoveTestFromCart = async (num?: number) => {
		if (num === 0) {
			removeLabTestCartItem(testId, handleCloseSelectPatientModal);
		}
	};

	const handleAddTestToCart = async (num?: number) => {
		const patientNumber = num && num > 0 ? num : patientCount;

		await labTestCartAction(testId, testData, patientNumber, selectedVendorId, handleNotFoundModal);
		handleCloseSelectPatientModal();
	};

	const handleAddToCartButton = async (num?: number) => {
		if (isSwitchVendor) {
			handleCloseSelectPatientModal && handleCloseSelectPatientModal();
			setTimeout(() => {
				handleOpenSwitchLabConf?.();
			}, 300);
			return;
		}
		handleAddTestToCart();
	};

	const handleCloseBookTestButtonModal = () => {
		handleCloseSelectPatientModal();
		setSelectedVendorId(cartData?.vendor?.id);
		setSelectedVendor(matchedVendors[0]);
		setPatientCount(currentItem?.patientCount || 1);
	};

	useEffect(() => {
		setSelectedVendorId(cartData?.vendor?.id);
		setPatientCount(currentItem?.patientCount || 1);
		setSelectedVendor(matchedVendors[0]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cartData?.vendor?.id, currentItem?.patientCount]);

	return (
		<>
			<div className={styles.bookTestButton}>
				{isAlreadyInCart ? (
					<div className={dropDownClassName}>
						{selectedLabel && (
							<p className='ltbody_lg__medium ltGreyscale600'>
								<span></span>
								{selectedLabel}
							</p>
						)}
						<Dropdown
							onClick={(e: MouseEvent<HTMLDivElement>) => {
								e.stopPropagation();
								if (isUnavailable) return;
								handleOpenSelectPatientModal();
							}}
							placeHolder={`${currentItem?.patientCount} ${currentItem?.patientCount > 1 ? 'Patients' : 'Patient'}`}
							btnVariant={`active`}
							btnSize={`${variant === 'cart_btn' ? 'xs' : 'fullWidth'}`}
							textSize={`${variant === 'cart_btn' && 'ltbody_sm__regular'}`}
						/>
					</div>
				) : (
					<div className={styles.testCard_patient_btn}>
						<Button
							variant='bgPrimary'
							size={`${variant === 'cart_btn' ? 'xs' : 'fullWidth'}`}
							onClick={(e) => {
								e.stopPropagation();
								if (isLogged) {
									handleOpenSelectPatientModal();
								} else {
									setShowLoginModal(true);
								}
							}}>
							{placeHolder}
						</Button>
					</div>
				)}
			</div>

			{/* Select Lab & Patient Modal */}
			{openSelectPatient && (
				<DialogModal open={openSelectPatient} closeModal={handleCloseSelectPatientModal}>
					<>
						{variant === 'cart_btn' && (
							<div className={styles.dialogModal_body_contents__cart}>
								<ModalHeader title='Book for' closeModal={handleCloseSelectPatientModal} />
								{isAlreadyInCart && (
									<BookforItem
										key={'Remove Test'}
										onClick={(e) => {
											e.stopPropagation();
											handleRemoveTestFromCart(0);
										}}>
										<p className={`ltbody_md__regular errorBase`}>Remove Test</p>
									</BookforItem>
								)}
								{[1, 2, 3, 4, 5]?.map((num) => (
									<BookforItem
										key={num}
										checked={currentItem?.patientCount === num}
										onClick={(e) => {
											e.stopPropagation();
											handleAddTestToCart(num);
										}}>
										<p className={`ltbody_md__regular ${String(num) === 'Remove Test' ? 'errorBase' : ''}`}>
											{`${num} ${num > 1 ? 'Patients' : 'Patient'}`}
										</p>
									</BookforItem>
								))}
							</div>
						)}

						{variant !== 'cart_btn' && (
							<div className={styles.dialogModal_body_contents}>
								<>
									<div className={styles.selectedTest}>
										<SelectLabTestCard data={testData} />
										<MdClose
											size={24}
											color='var(--ltGreyscale400)'
											onClick={() => {
												handleCloseBookTestButtonModal();
											}}
											style={{
												position: 'absolute',
												top: '20px',
												right: '20px',
												cursor: 'pointer'
											}}
										/>
									</div>
									<Line />
									<div className={styles.patientCount}>
										<SelectTestPatientCard
											patientCount={patientCount}
											setPatientCount={setPatientCount}
											selectedPatientCount={currentItem?.patientCount}
										/>
									</div>
									<Line />
									<div className={styles.noteInfo}>
										<ToastNote
											/* text={
												!isEmptyArray(cartData?.items)
													? 'Changing lab will change your previously selected lab'
													: 'Only one lab can be selected per order'
											} */
											text='Only one lab can be selected per order'
											// icon={
											// 	<MdInfo size={24} color={!isEmptyArray(cartData?.items) ? '#FD6A6A' : '#0062FF'} />
											// }
											icon={<MdInfo size={24} color={'#0062FF'} />}
											// variant={!isEmptyArray(cartData?.items) ? 'bgSeaShell' : 'bgGhostWhite'}
											variant={'bgGhostWhite'}
											color='ltGreyscale700'
										/>
									</div>
									{cartData?.vendor?.id && (
										<div className='px-24'>
											<div className='ltbody_lg__semibold ltGreyscale900 pb-16'>Current Lab</div>
											<div className={styles.vendorList}>
												{matchedVendors?.map((vendorItem) => (
													<SelectLabVendorCard
														data={vendorItem}
														key={vendorItem?.id}
														vendorStatus={vendorItem?.status as 'active' | 'inactive'}
														selectedVendorId={selectedVendorId}
														setSelectedVendorId={setSelectedVendorId}
														setSelectedVendor={setSelectedVendor}
														cartVendorId={cartData?.vendor?.tag}
														isEmptyCartItem={isEmptyArray(cartData?.items)}
													/>
												))}
											</div>
										</div>
									)}
									<div className={styles.labVendors}>
										<div className='ltbody_lg__semibold ltGreyscale900 pb-16'>
											{/* {cartData?.vendor?.id ? 'Change Lab' : 'Select Lab'} */}
											Change Lab
										</div>
										<div className={styles.vendorList}>
											{unmatchedVendors?.map((vendorItem) => (
												<SelectLabVendorCard
													data={vendorItem}
													key={vendorItem?.id}
													vendorStatus={vendorItem?.status as 'active' | 'inactive'}
													selectedVendorId={selectedVendorId}
													setSelectedVendorId={setSelectedVendorId}
													setSelectedVendor={setSelectedVendor}
													cartVendorId={cartData?.vendor?.tag}
													isEmptyCartItem={isEmptyArray(cartData?.items)}
												/>
											))}
										</div>
									</div>
								</>
								<div className={styles.dialogModal_footer}>
									{addToCartBtnDisabled() ? (
										<Button disabled size='fullWidth'>
											Add to Cart
										</Button>
									) : (
										<Button variant='bgPrimary' size='fullWidth' onClick={() => handleAddToCartButton()}>
											Add to Cart
										</Button>
									)}
								</div>
							</div>
						)}
					</>
				</DialogModal>
			)}

			{openNotfound && (
				<StateFullModal open={openNotfound} closeModal={handleCloseNotfound}>
					<PopUpModal
						modalType={`not_available`}
						title={`Sorry`}
						errorTag={notAvailableRes?.data?.errorTag}
						message={notAvailableRes?.message}
						cancelDelete={(e) => {
							e.stopPropagation();
							handleCloseNotfound();
						}}
					/>
				</StateFullModal>
			)}

			{openSwitchLabConf && (
				<DialogModal open={openSwitchLabConf} closeModal={handleCloseSwitchLabConf}>
					<SwitchLabConfirmationCard
						handleCloseSwitchLabConf={handleCloseSwitchLabConf}
						handleOpenSelectPatientModal={handleOpenSelectPatientModal}
						handleAddTestToCart={handleAddTestToCart}
						cartVendor={cartData?.vendor}
						selectedVendor={selectedVendor}
						setSelectedVendorId={setSelectedVendorId}
						setPatientCount={setPatientCount}
						currentItemPatientCount={currentItem?.patientCount}
					/>
				</DialogModal>
			)}
		</>
	);
};

export default BookTestButton;
