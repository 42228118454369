import labTestEmptyImage from '@labtest/ltassets/testCard_Empty_Image.png';
import Image from 'next/image';

import type { IG_LT_MostBookedTest } from '@labServices/apis/types/lab-tests/type.getMostBookedLabTests';
import type { IG_LT_TestDetails } from '@labServices/apis/types/lab-tests/type.getTestOrPackageDetails';

import styles from './SelectLabTestCard.module.scss';

interface I_Props {
	data: IG_LT_MostBookedTest | IG_LT_TestDetails;
}

const SelectLabTestCard = ({ data }: I_Props) => {
	return (
		<div className={styles.selectLabTestCard}>
			<div className={styles.icon_wrap}>
				<Image
					src={data?.bannerImage?.web || labTestEmptyImage}
					alt='Lab Test Icon'
					width={100}
					height={100}
					unoptimized
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						objectPosition: 'center'
					}}
				/>
			</div>
			<div className={styles.selectLabTestCard_content}>
				<p className='ltbody_xlg__semibold ltGreyscale900 mb-8 singleLine_ellipsis'>{data?.name?.en}</p>
				<p className='ltbody_md__regular ltGreyscale600 singleLine_ellipsis mb-12'>{data?.subTitle?.en}</p>
				<p className='ltbody_md__regular primaryBase'>
					Includes {data?.itemType === 'test' ? '1 Test' : `${data?.testCount} Tests`}
				</p>
			</div>
		</div>
	);
};

export default SelectLabTestCard;
