'use client';

import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa6';

import styles from './SelectTestPatientCard.module.scss';

interface I_Props {
	patientCount: number;
	setPatientCount: Dispatch<SetStateAction<number>>;
	selectedPatientCount?: number;
}

const SelectTestPatientCard = ({ patientCount, setPatientCount, selectedPatientCount }: I_Props) => {
	const [count, setCount] = useState<number>(selectedPatientCount || 1);
	const patientLabel = useMemo(() => (patientCount > 1 ? 'Patients' : 'Patient'), [patientCount]);

	const handleIncrement = () => {
		if (count < 5) {
			setCount((prev) => prev + 1);
			setPatientCount(count + 1);
		}
	};

	const handleDecrement = () => {
		if (count > 1) {
			setCount((prev) => prev - 1);
			setPatientCount(count - 1);
		}
	};

	return (
		<div className={styles.selectTestPatientCard}>
			<div className='ltbody_lg__semibold ltGreyscale900'>Select Patients</div>
			<div className={styles.selectPatient_counter}>
				<div className={styles.minus} onClick={handleDecrement}>
					<FaMinus size={14} color='var(--ltGreyscale900)' />
				</div>
				<div className='ltbody_lg__semibold primaryBase'>
					<span className={styles.count}>{patientCount}</span> <span>{patientLabel}</span>
				</div>
				<div className={styles.plus} onClick={handleIncrement}>
					<FaPlus size={14} color='var(--ltWhite)' />
				</div>
			</div>
		</div>
	);
};

export default SelectTestPatientCard;
