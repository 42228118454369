import useOutsideClick from '@labServices/hooks/useOutsideClick';

import { useRef } from 'react';
import { MdClose } from 'react-icons/md';

import styles from './SideDrawer.module.scss';

interface I_Props {
	open: boolean;
	onClose: () => void;
	title?: string;
	children: React.ReactNode;
}

const SideDrawer = ({ open, onClose, title, children }: I_Props) => {
	const ref = useRef();
	useOutsideClick(ref, onClose);

	return (
		<div ref={ref} className={`${styles.sideDrawer} ${open ? styles.open : ''}`}>
			{title !== 'Package Details' && (
				<div className={styles.sideDrawer_head}>
					<p className='ltbody_xlg__semibold ltGreyscale900'>{title || 'Cart'}</p>
					<MdClose size={24} color='var(--ltErrorDark)' onClick={onClose} />
				</div>
			)}

			{children}
		</div>
	);
};

export default SideDrawer;
