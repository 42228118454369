import styles from './IconWrapper.module.scss';

interface I_Props {
	variant?: 'bgCultured';
	size?: 'sm_32' | 'md_48' | 'lg_64' | 'xl_84';
	children: React.ReactNode;
}

const IconWrapper = ({ variant, children, size }: I_Props) => {
	return <div className={`${styles.icon} ${size ? styles[size] : ''} ${variant && styles.variant}`}>{children}</div>;
};

export default IconWrapper;
